import React from "react";
import { TextContainer, Paragraph } from "@siteimprove/fancylib";
import {
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	Knobs,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { SearchNavigation } from "./search-navigation";

export const Meta: DocPageMeta = {
	category: "Navigation",
	title: "Search Navigation",
	//notepad: "https://hackmd.io/d6TpHmYiS6eGp1r7N_WQhw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Search Navigation"
			subTitle="Present a navigation menu's item in a popover for the user to quickly search in by keyboard."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />

				<ImportExample lab component={SearchNavigation} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					A <Code>SearchNavigation</Code> allows the user to choose a specific navigation item by
					browsing a flattened list of them.
				</Paragraph>
				<Paragraph>
					The items must implement the properties defined in type{" "}
					<Code>SearchableNavigationItem</Code>.
				</Paragraph>

				<Example fn={BasicUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={SearchNavigation}
					initialProps={{
						hotkey: "Q",
						items: [
							{
								id: "firstMenuItem",
								title: "First menu item",
								href: "http://example.com",
							},
							{
								id: "secondMenuItemWithSubItems",
								title: "Second menu item with sub-items",
								href: "http://example.com",
								children: [
									{ id: "subMenuA", title: "Submenu A", href: "http://example.com" },
									{ id: "subMenuB", title: "Submenu B", href: "http://example.com" },
								],
							},
						],
					}}
				/>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<SearchNavigation
		hotkey="m"
		items={[
			{
				id: "myMenuA",
				title: "My Menu A",
				children: [
					{ id: "myLinkPlain", title: "My Link plain", href: "http://example.com" },
					{
						id: "myLinkOpenNew",
						title: "My Link openNew",
						href: "http://example.com",
						openNew: true,
					},
					{ id: "myButton", title: "My Button", onClick: () => alert("clicked") },
				],
			},
			{
				id: "myMenuB",
				title: "My Menu B",
				children: [
					{
						id: "mySubA",
						title: "My Sub A",
						children: [
							{
								id: "link",
								title: "Link",
								href: "http://example.com",
							},
						],
					},
					{
						id: "mySubB",
						title: "My Sub B",
						children: [
							{
								id: "mySubBB",
								title: "My Sub BB",
								children: [
									{
										id: "myItemSubBB",
										title: "My Item Sub BB",
										href: "http://example.com",
									},
								],
							},
							{
								id: "mySubBC",
								title: "My Sub BC",
								href: "http://example.com",
							},
						],
					},
				],
			},
			{
				id: "myMenuC",
				title: "My Menu C",
			},
		]}
	/>
);
